<template>
    <div>
      <client-accounting-invoice-alert-day-report-list></client-accounting-invoice-alert-day-report-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  <template>
    <div>
      <client-accounting-invoice-alert-day-report></client-accounting-invoice-alert-day-report>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  